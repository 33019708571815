import { template as template_93b237d9087e4af6bcfc2feaa64c46df } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_93b237d9087e4af6bcfc2feaa64c46df(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
